@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap);
  
/* base styles */
body {
  font-family: Raleway, sans-serif;
  margin: 0;
  font-size: 1.5em;
  text-align: center;
  background: #1b1523;
  color: #fff;
}

.App {
  max-width: 860px;
  margin: 40px auto;
}
button {
  background: none;
  border: 2px solid #fff;
  padding: 6px 12px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
button:hover {
  background: #c23866;
  color: #fff;
}
.card-grid {

  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap :20px
}
.card  {
    position: relative;
}
.card img {
 width: 100;
 display: block;
 border: 2px solid #fff;
 border-radius: 6px;
}
.card .front {
    -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
    transition: all ease-in 0.2s;
    position: absolute;

}
.flipped .front {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    transition-delay: 0.2s;
}

.card .back {
    
    transition: all ease-in 0.2s;
    transition-delay: 0.2s;

}
.flipped .back {
    -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
    transition-delay: 0s;
}
