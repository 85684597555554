.card  {
    position: relative;
}
.card img {
 width: 100;
 display: block;
 border: 2px solid #fff;
 border-radius: 6px;
}
.card .front {
    transform: rotateY(90deg);
    transition: all ease-in 0.2s;
    position: absolute;

}
.flipped .front {
    transform: rotateY(0deg);
    transition-delay: 0.2s;
}

.card .back {
    
    transition: all ease-in 0.2s;
    transition-delay: 0.2s;

}
.flipped .back {
    transform: rotateY(90deg);
    transition-delay: 0s;
}